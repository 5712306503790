import { FC, lazy, LazyExoticComponent } from 'react';

import { ROUTES } from 'constant/routes';
import { FeatureFlags } from '../store/config/types';
import EmptyFallback from 'components/fallback/EmptyFallback';
import ChatFallback from 'components/fallback/chatFallback/ChatFallback';
import FeedFallback from 'components/fallback/feedFallback/FeedFallback';
import ShopFallback from 'components/fallback/shopFallback/ShopFallback';

interface IRouteDetails {
  path: string;
  element: LazyExoticComponent<any>;
  fallback: FC;
  isWithLayout: boolean;
}

export const routes: Array<IRouteDetails> = [
  {
    path: ROUTES.HOME,
    element: lazy(() => import('pages/feed/Feed')),
    fallback: FeedFallback,
    isWithLayout: true,
  },
  {
    path: ROUTES.PRIVACY,
    element: lazy(() => import('pages/privacyTerms/Privacy')),
    fallback: EmptyFallback,
    isWithLayout: true,
  },
  {
    path: ROUTES.TERMS,
    element: lazy(() => import('pages/privacyTerms/Terms')),
    isWithLayout: true,
    fallback: EmptyFallback,
  },
  {
    path: ROUTES.DISCOVER,
    element: lazy(() => import('pages/discoverPage/DiscoverPage')),
    isWithLayout: true,
    fallback: EmptyFallback,
  },
  {
    path: `${ROUTES.ITEMS}/:category/*`,
    element: lazy(() => import('pages/productsCategoryPage/ProductsCategoryPage')),
    isWithLayout: true,
    fallback: EmptyFallback,
  },
  {
    path: `${ROUTES.ACTIVITIES}`,
    element: lazy(() => import('pages/activities/PostActivities')),
    isWithLayout: true,
    fallback: EmptyFallback,
  },
  {
    path: `${ROUTES.POST}/:id`,
    element: lazy(() => import('pages/specificPost/SpecificPost')),
    isWithLayout: true,
    fallback: EmptyFallback,
  },
  {
    path: `${ROUTES.ORDER}/:id/*`,
    element: lazy(() => import('pages/specificOrder/SpecificOrder')),
    isWithLayout: true,
    fallback: EmptyFallback,
  },
  {
    path: `${ROUTES.POST}/:id`,
    element: lazy(() => import('pages/specificPost/SpecificPost')),
    isWithLayout: true,
    fallback: EmptyFallback,
  },
  {
    path: `${ROUTES.SHOP}/:shopId`,
    element: lazy(() => import('pages/shopPage/ShopPage')),
    isWithLayout: true,
    fallback: ShopFallback,
  },
  {
    path: `${ROUTES.SEARCH}/*`,
    element: lazy(() => import('pages/searchResult/SearchResult')),
    isWithLayout: true,
    fallback: EmptyFallback,
  },
  {
    path: `${ROUTES.MY_SHOPS}/*`,
    element: lazy(() => import('pages/storesPage/StoresPage')),
    isWithLayout: true,
    fallback: EmptyFallback,
  },
  {
    path: `${ROUTES.CHAT}`,
    element: lazy(() => import('pages/chatv2/Chat')),
    isWithLayout: true,
    fallback: ChatFallback,
  },
  {
    path: `${ROUTES.CHAT}/:id`,
    element: lazy(() => import('pages/chatv2/Chat')),
    isWithLayout: true,
    fallback: EmptyFallback,
  },
  {
    path: `${ROUTES.CHAT_GROUP}/:id`,
    element: lazy(() => import('pages/chatv2/Chat')),
    isWithLayout: true,
    fallback: EmptyFallback,
  },
  {
    path: `${ROUTES.SIMILAR_SHOPS}/:id`,
    element: lazy(() => import('pages/storesPage/StoresPage')),
    isWithLayout: true,
    fallback: EmptyFallback,
  },
  {
    path: `${ROUTES.SETTINGS}`,
    element: lazy(() => import('pages/settings/Settings')),
    isWithLayout: true,
    fallback: EmptyFallback,
  },
  {
    path: `${ROUTES.HISTORY}/*`,
    element: lazy(() => import('pages/storesPage/StoresPage')),
    isWithLayout: true,
    fallback: EmptyFallback,
  },
  {
    path: `${ROUTES.FOLLOWING}/*`,
    element: lazy(() => import('pages/storesPage/StoresPage')),
    isWithLayout: true,
    fallback: EmptyFallback,
  },
  {
    path: `${ROUTES.PROFILE}/:userId`,
    element: lazy(() => import('pages/profilePage/ProfilePage')),
    isWithLayout: true,
    fallback: EmptyFallback,
  },
  {
    path: ROUTES.APP_CN,
    element: lazy(() => import('pages/appSwitch/AppSwitch')),
    isWithLayout: true,
    fallback: EmptyFallback,
  },
  {
    path: ROUTES.APP,
    element: lazy(() => import('pages/appSwitch/AppSwitch')),
    isWithLayout: true,
    fallback: EmptyFallback,
  },
  {
    path: ROUTES.ORDERS,
    element: lazy(() => import('pages/orders/OrdersPage')),
    isWithLayout: true,
    fallback: EmptyFallback,
  },
  {
    path: `${ROUTES.PRODUCT}/:productId`,
    element: lazy(() => import('pages/specificProductPage/ProductPage')),
    isWithLayout: true,
    fallback: EmptyFallback,
  },
  {
    path: `${ROUTES.REDIRECT_URL}/*`,
    element: lazy(() => import('pages/redirectLandingPage/RedirectLandingPage')),
    isWithLayout: true,
    fallback: EmptyFallback,
  },
  {
    path: `${ROUTES.PRODUCTS}/*`,
    element: lazy(() => import('pages/productsPage/ProductsPage')),
    isWithLayout: true,
    fallback: EmptyFallback,
  },
  {
    path: `${ROUTES.MOJOS}`,
    element: lazy(() => import('pages/mojosPage/MojosPage')),
    isWithLayout: true,
    fallback: EmptyFallback,
  },
  {
    path: `${ROUTES.MOJO}/:id`,
    element: lazy(() => import('pages/mojosPage/MojosPage')),
    isWithLayout: true,
    fallback: EmptyFallback,
  },
  {
    path: `${ROUTES.VISIONS}`,
    element: lazy(() => import('pages/visionsPage/VisionsPage')),
    isWithLayout: true,
    fallback: EmptyFallback,
  },
  {
    path: `${ROUTES.VISION}/:id`,
    element: lazy(() => import('pages/specificVisionPage/SpecificVisionPage')),
    isWithLayout: true,
    fallback: EmptyFallback,
  },
  {
    path: `${ROUTES.GENERATE_VISION}`,
    element: lazy(() => import('pages/generateVisionPage/GenerateVisionPage')),
    isWithLayout: true,
    fallback: EmptyFallback,
  },
  {
    path: `${ROUTES.VISIONS_LIST}`,
    element: lazy(() => import('pages/visionsListPage/VisionsListPage')),
    isWithLayout: true,
    fallback: EmptyFallback,
  },
  {
    path: `${ROUTES.MY_REQUESTS}`,
    element: lazy(() => import('pages/visionsRequestsPage/VisionsRequestsPage')),
    isWithLayout: true,
    fallback: EmptyFallback,
  },
  {
    path: `${ROUTES.BUYER_REQUESTS}`,
    element: lazy(() => import('pages/visionsOffersPage/VisionsOffersPage')),
    isWithLayout: true,
    fallback: EmptyFallback,
  },
  {
    path: `${ROUTES.SPECIFIC_VISION_REQUEST}/:id`,
    element: lazy(() => import('pages/specificVisionRequestPage/SpecificVisionRequestPage')),
    isWithLayout: true,
    fallback: EmptyFallback,
  },
  {
    path: `${ROUTES.BASKET}`,
    element: lazy(() => import('pages/basketPage/BasketPage')),
    isWithLayout: true,
    fallback: EmptyFallback,
  },
  {
    path: `${ROUTES.CREATE_SHOP}/:status`,
    element: lazy(() => import('pages/createShop/CreateShop')),
    isWithLayout: false,
    fallback: EmptyFallback,
  },
  {
    path: `${ROUTES.NON_SIGNUP_CHECKOUT}`,
    element: lazy(() => import('pages/checkoutPage/CheckoutPageWrapper')),
    isWithLayout: false,
    fallback: EmptyFallback,
  },
  {
    path: ROUTES.CREATE_SHOP,
    element: lazy(() => import('pages/createShop/CreateShop')),
    isWithLayout: false,
    fallback: EmptyFallback,
  },
  {
    path: ROUTES.EDIT_SHOP,
    element: lazy(() => import('pages/editShopSection/EditShopSection')),
    fallback: EmptyFallback,
    isWithLayout: false,
  },
  {
    path: `${ROUTES.USER}/:userId`,
    element: lazy(() => import('pages/userPage/UserPage')),
    isWithLayout: false,
    fallback: ShopFallback,
  },
  {
    path: `${ROUTES.SIGN}`,
    element: lazy(() => import('pages/signPage/SignPage')),
    isWithLayout: false,
    fallback: EmptyFallback,
  },
  {
    path: '*',
    element: lazy(() => import('pages/error/Error')),
    isWithLayout: true,
    fallback: EmptyFallback,
  },
];

export const mapRouteToFeatureFlag = {
  [ROUTES.VISIONS]: FeatureFlags.Visions,
  [ROUTES.VISIONS_LIST]: FeatureFlags.Visions,
  [ROUTES.VISION]: FeatureFlags.Visions,
  [ROUTES.SPECIFIC_VISION_REQUEST]: FeatureFlags.Visions,
  [ROUTES.GENERATE_VISION]: FeatureFlags.Visions,
  [ROUTES.MY_REQUESTS]: FeatureFlags.Visions,
  [ROUTES.BUYER_REQUESTS]: FeatureFlags.Visions,
};

export const nonSignInUsersSupportedRoutes = [
  ROUTES.HOME,
  ROUTES.SIGN,
  ROUTES.DISCOVER,
  ROUTES.NON_SIGNUP_CHECKOUT,
  ROUTES.PRIVACY,
  ROUTES.APP,
  ROUTES.APP_CN,
  ROUTES.TERMS,
  `${ROUTES.ORDER}/:id/*`,
  `${ROUTES.USER}/:userId`,
  `${ROUTES.SEARCH}/*`,
  `${ROUTES.PRODUCT}/:productId`,
  `${ROUTES.ITEMS}/:category/*`,
  `${ROUTES.SHOP}/:shopId`,
  `${ROUTES.PRODUCTS}/*`,
  `${ROUTES.POST}/:id`,
  `${ROUTES.REDIRECT_URL}/*`,
];

export const checkIfPathBlockedByNonLogin = (token: string | null, path: string) => {
  return !token && !nonSignInUsersSupportedRoutes.includes(path);
};

export const checkIfPathBlockedByFeatureFlag = (path: string, flags: FeatureFlags[]) => {
  const isFeatureFlagRequired = !!mapRouteToFeatureFlag[path];

  return isFeatureFlagRequired && !flags.includes(mapRouteToFeatureFlag[path]);
};
